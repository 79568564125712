import React, { useState, useEffect } from "react";
import "./Hero.css";

const Hero = ({ heroData }) => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % heroData.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [heroData.length]);

  return (
    <div className="carousel-hero-container">
      {heroData.map((hero, index) => (
        <div
          key={index}
          className={`carousel-hero-slide ${
            index === currentImage ? "active" : ""
          }`}
        >
          {hero.video ? (
            <video
              autoPlay
              muted
              loop
              playsInline
              className="carousel-hero-video"
            >
              <source src={hero.video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={hero.image} alt={`Slide ${index + 1}`} />
          )}
          <div className="text-overlay">
            <h2>{hero.subHeader}</h2>
            <h1>{hero.header}</h1>
            <p dangerouslySetInnerHTML={{ __html: hero.paragraph }} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Hero;
