import React from "react";

const LoadboardIcon = ({ className = "", style = {} }) => (
  <svg
    className={className}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor" // This allows you to set the color of the icon via props or CSS
    width="24px"
    height="24px"
  >
    <path d="M21,3H3C1.897,3,1,3.897,1,5v14c0,1.103,0.897,2,2,2h18c1.103,0,2-0.897,2-2V5C23,3.897,22.103,3,21,3z M21,19H3V5h18V19z" />
    <path d="M7 7H5v2h2V7zM7 11H5v2h2v-2zM7 15H5v2h2v-2zM11 7H9v2h2V7zM11 11H9v2h2v-2zM11 15H9v2h2v-2zM15 7h-2v2h2V7zM15 11h-2v2h2v-2zM15 15h-2v2h2v-2z" />
  </svg>
);

export default LoadboardIcon;
