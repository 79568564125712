import React from "react";
import { motion } from "framer-motion";
import LoadItem from "../components/LoadItem/LoadItem";
import "./DashboardContent.css";

const DashboardContent = ({
  pastShipments,
  pastDeliveries,
  inProgressShipments,
  isLoading,
  companyType,
}) => {
  return (
    <motion.div
      className="dashboardContent-content"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {companyType === "Shipping" && (
        <>
          <div className="dashboard-wrapper">
            <div className="in-progress-shipments">
              <h2>In Progress Shipments</h2>
              {isLoading ? (
                <p>Loading...</p>
              ) : inProgressShipments.length > 0 ? (
                inProgressShipments.map((shipment) => (
                  <LoadItem key={shipment._id} shipment={shipment} />
                ))
              ) : (
                <p>No in-progress shipments found.</p>
              )}
            </div>

            <div className="past-shipments">
              <h2>Past Shipments</h2>
              {isLoading ? (
                <p>Loading...</p>
              ) : pastShipments.length > 0 ? (
                pastShipments.map((shipment) => (
                  <LoadItem key={shipment._id} shipment={shipment} />
                ))
              ) : (
                <p>No past shipments found.</p>
              )}
            </div>
          </div>
        </>
      )}

      {companyType === "Delivery" && (
        <>
          <div className="in-progress-shipments">
            <h2>In Progress Shipments</h2>
            {isLoading ? (
              <p>Loading...</p>
            ) : inProgressShipments.length > 0 ? (
              inProgressShipments.map((shipment) => (
                <LoadItem key={shipment._id} shipment={shipment} />
              ))
            ) : (
              <p>No in-progress shipments found.</p>
            )}
          </div>

          <div className="past-deliveries">
            <h2>Past Deliveries</h2>
            {isLoading ? (
              <p>Loading...</p>
            ) : pastDeliveries.length > 0 ? (
              pastDeliveries.map((delivery) => (
                <LoadItem key={delivery._id} shipment={delivery} />
              ))
            ) : (
              <p>No past deliveries found.</p>
            )}
          </div>
        </>
      )}
    </motion.div>
  );
};

export default DashboardContent;
