const TOKEN_KEY = "token";
const REFRESH_TOKEN_KEY = "refreshToken";
const COMPANY_KEY = "company";

export const setStoredToken = (token) => localStorage.setItem(TOKEN_KEY, token);
export const getStoredToken = () => localStorage.getItem(TOKEN_KEY);
export const removeStoredToken = () => localStorage.removeItem(TOKEN_KEY);

export const setStoredCompany = (company) => {
  try {
    const companyToStore = company
      ? JSON.stringify(company)
      : JSON.stringify(getDefaultCompany());
    localStorage.setItem(COMPANY_KEY, companyToStore);
  } catch (error) {
    console.error(`Error setting company data: ${error.message}`);
  }
};

export const getStoredCompany = () => {
  try {
    const storedCompany = localStorage.getItem(COMPANY_KEY);
    return storedCompany ? JSON.parse(storedCompany) : null;
  } catch (error) {
    console.error(`Error retrieving company data: ${error.message}`);
    return null;
  }
};

export const removeStoredCompany = () => localStorage.removeItem(COMPANY_KEY);

const getDefaultCompany = () => ({
  companyId: "defaultId",
  companyName: "Default Company",
});

export const setStoredRefreshToken = (refreshToken) => {
  if (refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  } else {
    console.error("Trying to set an empty or undefined refresh token.");
  }
};

export const removeStoredRefreshToken = () =>
  localStorage.removeItem(REFRESH_TOKEN_KEY);
