// import React from "react";
// import "./HomeServices.css";
// import BackgroundImage from "./Images/bg.jpg";
// import Struck from "./Images/struck.png";

import React from "react";
import "./HomeServices.css";
import BackgroundImage from "./Images/bg.jpg";
import Struck from "./Images/struck.png";
import { motion } from "framer-motion";
import miniTruck from "./Images/image 2.png";

const HomeServices = () => {
  return (
    <div
      className="home-services"
      style={{
        background: `url(${BackgroundImage}) no-repeat center center/cover`,
      }}
    >
      <div className="container">
        <div className="service-section">
          <div className="service-section-left">
            <div className="home-headings">
              <h3>Our Services</h3>
              <h1>What we offer</h1>
            </div>

            <div className="home-service-para">
              <p>
                Explore the many services designed to enhance your shipping
                experience with us. Our offerings include seamless state-wide
                delivery, swift Same Day Delivery, efficient Load Posting,
                round-the-clock 24X7 Support, enticing Discounts, and a
                User-Friendly interface. At The Wright Logistics, we treat our
                clients like cherished members of our family, ensuring their
                needs are met with the utmost care and dedication. Experience
                the convenience and reliability that define our commitment to
                exceptional service.
              </p>
            </div>

            <div className="service-item1">
              <ServiceItem
                title="State Wide Delivery"
                description="Logistics management is the part of supply chain"
                iconPath="M480-40q-112 0-206-51T120-227v107H40v-240h240v80h-99q48 72 126.5 116T480-120q75 0 140.5-28.5t114-77q48.5-48.5 77-114T840-480h80q0 91-34.5 171T791-169q-60 60-140 94.5T480-40Zm-36-160v-52q-47-11-76.5-40.5T324-370l66-26q12 41 37.5 61.5T486-314q33 0 56.5-15.5T566-378q0-29-24.5-47T454-466q-59-21-86.5-50T340-592q0-41 28.5-74.5T446-710v-50h70v50q36 3 65.5 29t40.5 61l-64 26q-8-23-26-38.5T482-648q-35 0-53.5 15T410-592q0 26 23 41t83 35q72 26 96 61t24 77q0 29-10 51t-26.5 37.5Q583-274 561-264.5T514-250v50h-70ZM40-480q0-91 34.5-171T169-791q60-60 140-94.5T480-920q112 0 206 51t154 136v-107h80v240H680v-80h99q-48-72-126.5-116T480-840q-75 0-140.5 28.5t-114 77q-48.5 48.5-77 114T120-480H40Z"
              />
              <ServiceItem
                title="Support 24X7"
                description="Logistics management is the part of supply chain"
                iconPath="M440-120v-80h320v-284q0-117-81.5-198.5T480-764q-117 0-198.5 81.5T200-484v244h-40q-33 0-56.5-23.5T80-320v-80q0-21 10.5-39.5T120-469l3-53q8-68 39.5-126t79-101q47.5-43 109-67T480-840q68 0 129 24t109 66.5Q766-707 797-649t40 126l3 52q19 9 29.5 27t10.5 38v92q0 20-10.5 38T840-249v49q0 33-23.5 56.5T760-120H440Zm-80-280q-17 0-28.5-11.5T320-440q0-17 11.5-28.5T360-480q17 0 28.5 11.5T400-440q0 17-11.5 28.5T360-400Zm240 0q-17 0-28.5-11.5T560-440q0-17 11.5-28.5T600-480q17 0 28.5 11.5T640-440q0 17-11.5 28.5T600-400Zm-359-62q-7-106 64-182t177-76q89 0 156.5 56.5T720-519q-91-1-167.5-49T435-698q-16 80-67.5 142.5T241-462Z"
              />
              <ServiceItem
                title="Same Day Delivery"
                description="Logistics management is the part of supply chain"
                iconPath="M240-160q-50 0-85-35t-35-85H40v-440q0-33 23.5-56.5T120-800h560v160h120l120 160v200h-80q0 50-35 85t-85 35q-50 0-85-35t-35-85H360q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T280-280q0-17-11.5-28.5T240-320q-17 0-28.5 11.5T200-280q0 17 11.5 28.5T240-240ZM120-360h32q17-18 39-29t49-11q27 0 49 11t39 29h272v-360H120v360Zm600 120q17 0 28.5-11.5T760-280q0-17-11.5-28.5T720-320q-17 0-28.5 11.5T680-280q0 17 11.5 28.5T720-240Zm-40-200h170l-90-120h-80v120ZM360-540Z"
              />
              <ServiceItem
                title="Discount"
                description="Logistics management is the part of supply chain"
                iconPath="M160-280v80h640v-80H160Zm0-440h88q-5-9-6.5-19t-1.5-21q0-50 35-85t85-35q30 0 55.5 15.5T460-826l20 26 20-26q18-24 44-39t56-15q50 0 85 35t35 85q0 11-1.5 21t-6.5 19h88q33 0 56.5 23.5T880-640v440q0 33-23.5 56.5T800-120H160q-33 0-56.5-23.5T80-200v-440q0-33 23.5-56.5T160-720Zm0 320h640v-240H596l84 114-64 46-136-184-136 184-64-46 82-114H160v240Zm200-320q17 0 28.5-11.5T400-760q0-17-11.5-28.5T360-800q-17 0-28.5 11.5T320-760q0 17 11.5 28.5T360-720Zm240 0q17 0 28.5-11.5T640-760q0-17-11.5-28.5T600-800q-17 0-28.5 11.5T560-760q0 17 11.5 28.5T600-720Z"
              />
              <ServiceItem
                title="Load Posting"
                description="Logistics management is the part of supply chain"
                iconPath="M120-120v-720h720v720H120Zm600-160H240v60h480v-60Zm-480-60h480v-60H240v60Zm0-140h480v-240H240v240Zm0 200v60-60Zm0-60v-60 60Zm0-140v-240 240Zm0 80v-80 80Zm0 120v-60 60Z"
              />
              <ServiceItem
                title="User Friendly"
                description="Logistics management is the part of supply chain"
                iconPath="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z"
              />
            </div>
          </div>
          <div className="service-section-right">
            <div className="service-truckImg">
              <img src={Struck} alt="Truck" />
            </div>
            <div className="miniTruck">
              <img src={miniTruck} alt="Mini Truck" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ServiceItem = ({ title, description, iconPath }) => (
  <div className="service-items">
    <div className="icon-circle">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="20"
        viewBox="0 -960 960 960"
        width="20"
      >
        <path d={iconPath} />
      </svg>
    </div>
    <div className="service-home-item">
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  </div>
);

export default HomeServices;
