import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./LoadItem.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faTruckMoving,
  faCheckCircle,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import ModalComponent from "../ModalComponent";
import { useAuth } from "../AuthContext/AuthContext";
import { useSnackbar } from "notistack";
import {
  GoogleMap,
  LoadScript,
  DirectionsRenderer,
} from "@react-google-maps/api";

const LoadItem = ({ shipment, handleShipmentUpdate }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { company, handleDeliveryUpdate } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const [completionNote, setCompletionNote] = useState("");
  const [directionsResponse, setDirectionsResponse] = useState(null);

  const isDeliveryCompany = company?.companyType === "Delivery";
  const canInteract = isDeliveryCompany && shipment.status === "Available";

const handlePickupConfirm = async (
  shipment,
  shipmentId,
  updatedShipmentData
) => {
  try {
    if (!company || !company.id) {
      throw new Error("Delivery company ID not found");
    }
    if (
      typeof updatedShipmentData !== "object" ||
      updatedShipmentData === null
    ) {
      throw new Error("Invalid updated shipment data");
    }

    if (
      !(
        "status" in updatedShipmentData &&
        "deliveryCompanyId" in updatedShipmentData
      )
    ) {
      throw new Error(
        "Required fields 'status' and 'deliveryCompanyId' are missing"
      );
    }

    const { status, deliveryCompanyId } = updatedShipmentData;

    if (status === "In Progress" && !deliveryCompanyId) {
      throw new Error(
        "Delivery company ID is required for picking up shipment"
      );
    }

    if (shipment.status !== "Available") {
      throw new Error("Shipment status must be 'Available' to pick up");
    }

    // Update shipment
    await handleDeliveryUpdate(shipmentId, updatedShipmentData);

    enqueueSnackbar("Shipment picked up successfully!", {
      variant: "success",
    });

    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin: shipment.origin,
        destination: shipment.destination,
        travelMode: "TRANSIT",
      },
      (response, status) => {
        if (status === "OK") {
          setDirectionsResponse(response);
          setModalOpen(true);
        } else {
          enqueueSnackbar("Failed to fetch directions.", {
            variant: "error",
          });
        }
      }
    );

    handleShipmentUpdate();
  } catch (error) {
    console.error("Error picking up shipment:", error);
    enqueueSnackbar("Failed to pick up shipment.", {
      variant: "error",
    });
  } finally {
    setModalOpen(false);
  }
};




  const handleCompleteButtonClick = async () => {
    try {
      await handleDeliveryUpdate(shipment._id, { status: "Completed" });
      enqueueSnackbar("Shipment completed successfully!", {
        variant: "success",
      });
      handleShipmentUpdate();
    } catch (error) {
      console.error("Error completing shipment:", error);
      enqueueSnackbar("Failed to complete shipment.", {
        variant: "error",
      });
    } finally {
      setModalOpen(false);
      setCompletionNote("");
    }
  };

  const renderStatusIconAndText = () => {
    let icon = null;
    let color = null;

    switch (shipment.status) {
      case "Available":
        icon = faCircleNotch;
        color = "green";
        break;
      case "In Progress":
        icon = faTruckMoving;
        color = "orange";
        break;
      case "Completed":
        icon = faCheckCircle;
        color = "blue";
        break;
      default:
        break;
    }

    return (
      <div className="status-text">
        <FontAwesomeIcon icon={icon} style={{ color }} />
        <span>{shipment.status}</span>
      </div>
    );
  };

  useEffect(() => {
    console.log("Can interact:", canInteract);
  }, [canInteract]);

  return (
    <motion.div
      className={`shipment-item ${canInteract ? "interactable" : ""}`}
      onClick={() => setModalOpen(true)}
    >
      <div className="status-icon">{renderStatusIconAndText()}</div>
      <div className="shipment-details">
        <div className="Box-truck">
          <FontAwesomeIcon icon={faTruckMoving} className="Box-truck-icon" />
        </div>
        <div className="shipment-details-items">
          <p>{shipment.origin}</p>
          <FontAwesomeIcon icon={faArrowRight} className="arrow-icon" />
          <p>{shipment.destination}</p>
        </div>
        <p>{new Date(shipment.departureDate).toLocaleDateString()}</p>
        <p>{new Date(shipment.arrivalDate).toLocaleDateString()}</p>
        <p>{shipment.cargoType}</p>
        <p>{shipment.cargoWeight} kg</p>
        <p>
          <strong>Cost Per Mile:</strong> ${shipment.costPerMile}
        </p>
        <p>
          <strong>Total Cost:</strong> ${shipment.totalCost}
        </p>
        <p>
          <strong>Notes:</strong> {shipment.notes}
        </p>
      </div>
      {canInteract && (
        <ModalComponent
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          onConfirm={handlePickupConfirm}
        >
          <div>
            <p>Are you sure you want to pick up this shipment?</p>
          </div>
        </ModalComponent>
      )}
      {shipment.status === "In Progress" && isDeliveryCompany && (
        <button className="complete-button" onClick={handleCompleteButtonClick}>
          Complete
        </button>
      )}
      <ModalComponent
        isOpen={isModalOpen && shipment.status === "In Progress"}
        onClose={() => {
          setModalOpen(false);
          setCompletionNote("");
        }}
        onConfirm={handleCompleteButtonClick}
      >
        <div>
          <p>Are you sure you want to complete this shipment?</p>
          <textarea
            value={completionNote}
            onChange={(e) => setCompletionNote(e.target.value)}
            placeholder="Add completion note (optional)"
          />
        </div>
      </ModalComponent>
      {directionsResponse && (
        <div style={{ height: "400px", width: "100%" }}>
          <LoadScript googleMapsApiKey={process.env.GOOGLE_DIRECTION_MAP}>
            <GoogleMap
              mapContainerStyle={{ height: "100%", width: "100%" }}
              center={shipment.origin}
            >
              <DirectionsRenderer directions={directionsResponse} />
            </GoogleMap>
          </LoadScript>
        </div>
      )}
    </motion.div>
  );
};

export default LoadItem;
