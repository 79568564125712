import React from "react";

const DeliveryTruckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="currentColor"
    className="bi bi-truck"
    viewBox="0 0 16 16"
  >
    <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5v9a1.5 1.5 0 0 1-1.5 1.5H10a1.5 1.5 0 0 1-3 0H4a1.5 1.5 0 0 1-3 0H1.5A1.5 1.5 0 0 1 0 12.5v-9ZM1 3.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v1ZM10 12a2 2 0 1 0 4 0 2 2 0 0 0-4 0Zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
    <path d="M12.5 6a.5.5 0 0 1 .5.5V10h1V6.5a1.5 1.5 0 0 0-1.5-1.5h-9A1.5 1.5 0 0 0 2 6.5v7a.5.5 0 0 0 1 0V11h10v2.5a.5.5 0 0 0 1 0v-7a.5.5 0 0 1 .5-.5Z" />
  </svg>
);

export default DeliveryTruckIcon;
