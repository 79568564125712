import React from "react";
import { motion } from "framer-motion";
import "./FillerSection.css";
import smallTruck from "./Images/smallmidsec.png";

const FillerSection = () => {
  return (
    <div className="filler-section">
      <motion.div
        className="bg-image"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <motion.div
          className="container filler-items"
          initial={{ y: -50 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1>WE OFFER QUICK & POWERFUL SOLUTIONS</h1>
          <motion.div
            className="FillerImg"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <img src={smallTruck} alt="Small Truck" />
          </motion.div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default FillerSection;
