import React from "react";
import { motion } from "framer-motion";
import LoadItem from "../LoadItem/LoadItem";
import "./LoadList.css";

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 120,
    },
  },
};

const LoadList = ({ shipments, handleShipmentUpdate }) => {
  if (shipments.length === 0) {
    return <div className="Loadlist">No upcoming loads found.</div>;
  }

  return (
    <motion.div
      className="Loadlist"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <h2>Upcoming Loads</h2>
      <motion.ul className="LoadList-Content">
        {shipments.map((shipment) => (
          <motion.li key={shipment._id} variants={itemVariants}>
            <LoadItem
              shipment={shipment}
              handleShipmentUpdate={handleShipmentUpdate}
            />
          </motion.li>
        ))}
      </motion.ul>
    </motion.div>
  );
};

export default LoadList;
