import React, { useState } from "react";
import { CgMenuRound, CgCloseO } from "react-icons/cg";
import Navlinks from "./Navlinks";

const MobileNavigation = () => {
  const [open, setOpen] = useState(false);

  const toggleMenu = () => setOpen(!open);

  return (
    <nav className="MobileNavigation">
      {open ? (
        <CgCloseO
          className="Hamburger"
          size="40px"
          color="white"
          onClick={toggleMenu}
        />
      ) : (
        <CgMenuRound
          className="Hamburger"
          size="40px"
          color="white"
          onClick={toggleMenu}
        />
      )}
      {open && <Navlinks isMobile={true} closeMobileMenu={toggleMenu} />}
    </nav>
  );
};

export default MobileNavigation;
