import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const AddressAutocomplete = ({ label, onChange }) => {
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState(null);

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(latLng);
    const { street, city, state, zip } = parseAddressComponents(results[0]);
    onChange({ street, city, state, zipCode: zip });
  };

  const parseAddressComponents = (place) => {
    let street = "";
    let city = "";
    let state = "";
    let zip = "";
    place.address_components.forEach((component) => {
      if (component.types.includes("street_number")) {
        street += component.long_name + " ";
      } else if (component.types.includes("route")) {
        street += component.long_name;
      } else if (component.types.includes("locality")) {
        city = component.long_name;
      } else if (component.types.includes("administrative_area_level_1")) {
        state = component.short_name;
      } else if (component.types.includes("postal_code")) {
        zip = component.long_name;
      }
    });
    return { street, city, state, zip };
  };

  return (
    <div className="loadboard-form-group">
      <label htmlFor={`${label}Street`}>{label} Street:</label>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              id={`${label}Street`}
              {...getInputProps({
                placeholder: `${label} Street`,
                className: "location-search-input",
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default AddressAutocomplete;
