import React from "react";
import "./HeaderNavbar.css";
import Logo from "../Logo-2.png";
import MobileNavigation from "./MobileNavigation";
import Navigation from "./Navigation";
import { Link } from "react-router-dom";

const HeaderNavbar = () => {
  return (
    <header className="Header">
      <div className="container">
        <nav className="Navbar">
          <div className="logo">
            <Link to="/">
              <img src={Logo} alt="Logo" width="160" />
            </Link>
          </div>
          <Navigation />
          <MobileNavigation />
        </nav>
      </div>
    </header>
  );
};

export default HeaderNavbar;
