
import React from "react";
import { motion } from "framer-motion";
import DeliveryTruckIcon from "../components/DeliveryTruckIcon"; 
import ProcessIcon from "../components/ProcessIcon"; 
import LoadboardIcon from "../components/LoadboardIcon"; 
import "./service.css";
import { FormTitle } from "../components/formTitle";
import Header from "./Header";

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.1, delayChildren: 0.2 },
  },
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { type: "spring", stiffness: 100 },
  },
};

const Services = () => (
  <div>
  <Header title="Services"/>
    <FormTitle  text="Services" />

    <motion.div
      className="services-container"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div className="service-item" variants={itemVariants}>
        <LoadboardIcon />
        <h2>Interactive Loadboard</h2>
        <p>
          Access our dynamic loadboard for real-time shipping opportunities.
        </p>
      </motion.div>

      <motion.div className="service-item" variants={itemVariants}>
        <DeliveryTruckIcon />
        <h2>Fast Delivery</h2>
        <p>Ensuring timely delivery of your packages nationwide.</p>
      </motion.div>

      <motion.div className="service-item" variants={itemVariants}>
        <ProcessIcon />
        <h2>Seamless Process</h2>
        <p>Our optimized process ensures efficiency from start to finish.</p>
      </motion.div>

    </motion.div>
  </div>
);

export default Services;
