import React from "react";

const ProcessIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="currentColor"
    className="bi bi-gear"
    viewBox="0 0 16 16"
  >
    <path d="M9.405 1.05a1 1 0 0 0-2.81 0l-.1.191a5.718 5.718 0 0 0-1.4.323l-.188.09a1 1 0 0 0-.513.513l-.09.188a5.718 5.718 0 0 0-.323 1.4l-.191.1a1 1 0 0 0 0 2.81l.191.1c.058.3.137.592.235.875l-.516.516a1 1 0 0 0 0 1.414l.708.708a1 1 0 0 0 1.414 0l.516-.516c.283.098.575.177.875.235l.1.191a1 1 0 0 0 2.81 0l.1-.191c.3-.058.592-.137.875-.235l.516.516a1 1 0 0 0 1.414 0l.708-.708a1 1 0 0 0 0-1.414l-.516-.516c.098-.283.177-.575.235-.875l.191-.1a1 1 0 0 0 0-2.81l-.191-.1a5.718 5.718 0 0 0-.235-.875l.516-.516a1 1 0 0 0 0-1.414l-.708-.708a1 1 0 0 0-1.414 0l-.516.516a5.718 5.718 0 0 0-.875-.235l-.1-.191ZM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858Z" />
  </svg>
);

export default ProcessIcon;
