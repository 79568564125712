import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import "./Footer.css";
import footerLogo from "./Images/footerlogoimg.png";

const socialMediaIcons = [
  {
    href: "https://www.facebook.com/thewrightlogisticsllc/",
    svgPath:
      "M12 2.04c-5.52 0-10 4.48-10 10 0 4.99 3.66 9.13 8.44 9.88v-7h-2.54v-2.88h2.54v-2.19c0-2.5 1.5-3.88 3.77-3.88 1.1 0 2.24.2 2.24.2v2.48h-1.26c-1.24 0-1.63.77-1.63 1.55v1.84h2.77l-.44 2.88h-2.33v7C18.34 21.17 22 17.03 22 12.04c0-5.52-4.48-10-10-10z",
  },
  {
    href: "https://x.com/wrightlogistic",
    svgPath:
      "M22.46 6c-.77.35-1.5.58-2.33.69.84-.5 1.48-1.3 1.78-2.26-.77.46-1.62.81-2.54 1-1.38-1.47-3.77-1.46-5.24 0-1.23 1.23-1.54 3.07-.78 4.66-2.88-.14-5.44-1.55-7.16-3.75-.94 1.61-.47 3.7 1.08 4.77-.64-.02-1.25-.19-1.77-.47v.04c0 1.61 1.12 3.12 2.73 3.47-.63.17-1.3.26-1.97.09.55 1.71 2.15 2.94 4.02 2.97-1.5 1.17-3.32 1.75-5.2 1.75-.34 0-.67-.02-1-.05 1.87 1.2 4.1 1.88 6.5 1.88 7.88 0 12.22-6.52 12.22-12.19 0-.19 0-.39-.02-.58.84-.6 1.57-1.34 2.15-2.2z",
  },
  {
    href: "https://www.linkedin.com/company/thewrightlogisticsllc/",
    svgPath:
      "M16 8c-2.67 0-4.33 1.33-5 2.34V8H8v12h3v-6c0-1.24.77-2 2-2s2 .76 2 2v6h3v-6.34c0-2.34-1.34-4.66-4-4.66zM5 8H2v12h3V8zm0-3c0 1-1 2-2 2s-2-1-2-2 1-2 2-2 2 1 2 2z",
  },
  {
    href: "https://www.instagram.com/thewrightlogistics/",
    svgPath:
      "M8 12c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4zm8-4.2c.68 0 1.24-.56 1.24-1.24s-.56-1.24-1.24-1.24-1.24.56-1.24 1.24.56 1.24 1.24 1.24zM12 2c2.8 0 3.14.01 4.25.06 1.05.05 1.75.22 2.17.36.52.18.89.39 1.28.77.39.39.59.75.77 1.28.15.42.31 1.12.36 2.17.05 1.11.06 1.45.06 4.25s-.01 3.14-.06 4.25c-.05 1.05-.22 1.75-.36 2.17-.18.52-.39.89-.77 1.28-.39.39-.75.59-1.28.77-.42.15-1.12.31-2.17.36-1.11.05-1.45.06-4.25.06s-3.14-.01-4.25-.06c-1.05-.05-1.75-.22-2.17-.36-.52-.18-.89-.39-1.28-.77-.39-.39-.75-.59-1.28-.77-.42-.15-1.12-.31-2.17-.36-1.11-.05-1.45-.06-4.25-.06s-3.14.01-4.25.06c-1.11.05-1.96.16-2.74.39-.78.23-1.41.52-1.97 1.08-.56.56-.85 1.19-1.08 1.97-.23.78-.34 1.63-.39 2.74-.05 1.11-.06 1.59-.06 4.25s.01 3.14.06 4.25c.05 1.11.16 1.96.39 2.74.23.78.52 1.41 1.08 1.97.56.56 1.19.85 1.97 1.08.78.23 1.63.34 2.74.39 1.11.05 1.59.06 4.25.06s3.14-.01 4.25-.06c1.11-.05 1.96-.16 2.74-.39.78-.23 1.41-.52 1.97-1.08.56-.56.85-1.19 1.08-1.97.23-.78.34-1.63.39-2.74.05-1.11.06-1.59.06-4.25s-.01-3.14-.06-4.25c-.05-1.11-.16-1.96-.39-2.74-.23-.78-.52-1.41-1.08-1.97-.56-.56-1.19-.85-1.97-1.08-.78-.23-1.63-.34-2.74-.39-1.11-.05-1.59-.06-4.25-.06z",
  },
];

const pathVariants = {
  hidden: { pathLength: 0, fill:  "rgba(255, 255, 255, 1)"  },
  visible: {
    pathLength: 1,
    fill: "rgb(234 172 15)",
    transition: { duration: 2, ease: "easeInOut" },
  },
};

const Footer = () => {
  return (
    <motion.div
      className="footer"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <div className="footer-container">
        <div className="container-wrapper">
          <div className="left-side">
            <motion.div
              className="footer-logo"
              initial={{ y: 50 }}
              animate={{ y: 0 }}
              transition={{ type: "spring", stiffness: 50 }}
            >
              <img src={footerLogo} alt="Footer Logo" />
              <p>The Wright way to move Freight</p>
              <div className="contact-info">
                <motion.svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 -960 960 960"
                  width="24"
                  initial="hidden"
                  whileHover="visible"
                  variants={pathVariants}
                >
                  <motion.path
                    d="M480-120q-112 0-206-51T120-227v107H40v-240h240v80h-99q48 72 126.5 116T480-120q75 0 140.5-28.5t114-77q48.5-48.5 77-114T840-480h80q0 91-34.5 171T791-169q-60 60-140 94.5T480-120Zm-36-160v-52q-47-11-76.5-40.5T324-370l66-26q12 41 37.5 61.5T486-314q33 0 56.5-15.5T566-378q0-29-24.5-47T454-466q-59-21-86.5-50T340-592q0-41 28.5-74.5T446-710v-50h70v50q36 3 65.5 29t40.5 61l-64 26q-8-23-26-38.5T482-648q-35 0-53.5 15T410-592q0 26 23 41t83 35q72 26 96 61t24 77q0 29-10 51t-26.5 37.5Q583-274 561-264.5T514-250v50h-70ZM40-480q0-91 34.5-171T169-791q60-60 140-94.5T480-920q112 0 206 51t154 136v-107h80v240H680v-80h99q-48 72-126.5 116T480-840q-75 0-140.5 28.5t-114 77q-48.5 48.5-77 114T120-480H40Z"
                    variants={pathVariants}
                  />
                </motion.svg>
                <p>(949) 919-4017</p>
              </div>
              <div className="social-icons">
                {socialMediaIcons.map((icon, index) => (
                  <motion.a
                    key={index}
                    href={icon.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    initial="hidden"
                    whileHover="visible"
                    variants={pathVariants}
                  >
                    <motion.svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                      initial="hidden"
                      whileHover="visible"
                      variants={pathVariants}
                    >
                      <motion.path d={icon.svgPath} variants={pathVariants} />
                    </motion.svg>
                  </motion.a>
                ))}
              </div>
            </motion.div>
          </div>

          <div className="middle-side">
            <h4>Pages</h4>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/service">Services</Link>
              </li>
              <li>
                <Link to="/loadboard">Load Board</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>

          <div className="right-side">
            <h4>Services</h4>
            <ul>
              <li>
                <Link to="/">Shipping</Link>
              </li>
              <li>
                <Link to="/">Delivery</Link>
              </li>
              <li>
                <Link to="/">Containers</Link>
              </li>
              <li>
                <Link to="/">Freight</Link>
              </li>
              <li>
                <Link to="/">Delivery</Link>
              </li>
            </ul>
          </div>

          <div className="newsletter">
            <h4>Newsletter</h4>
            <p>Please sign up for updates on everything new.</p>
            <input type="email" placeholder="Enter Email Address...." />
            <motion.button
              type="submit"
              className="submit-btn"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Subscribe
            </motion.button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Footer;
