import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "./HeaderNavbar.css";
import { useAuth } from "./AuthContext/AuthContext";

const Navlinks = ({ closeMobileMenu = () => {} }) => {
  const { isAuthenticated, handleLogout } = useAuth();
  const [showSubmenu, setShowSubmenu] = useState(false);

  const links = [
    { path: "/", label: "Home" },
    { path: "/service", label: "Service" },
    { path: "/about", label: "About" },
    { path: "/loadboard", label: "LoadBoard", submenu: true },
    { path: "/contact", label: "Contact" },
  ];

  const authLinks = isAuthenticated
    ? [{ label: "Logout", onClick: () => handleLogout().then(closeMobileMenu) }]
    : [
        { path: "/login", label: "Login" },
        { path: "/register", label: "Register" },
      ];

  return (
    <nav className="nav">
      <ul className="Navbar-links">
        {links.map((link, index) => (
          <motion.li
            key={link.label}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            onMouseEnter={() => link.submenu && setShowSubmenu(true)}
            onMouseLeave={() => link.submenu && setShowSubmenu(false)}
          >
            <Link to={link.path} onClick={closeMobileMenu}>
              {link.label} {link.submenu && <span>&#x25BC;</span>}
            </Link>
            {showSubmenu && link.submenu && (
              <ul className="Submenu">
                <li>
                  <Link to="/dashboard" onClick={closeMobileMenu}>
                    Dashboard
                  </Link>
                </li>
              </ul>
            )}
          </motion.li>
        ))}
        {authLinks.map((link, index) => (
          <motion.li
            key={link.label}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: (links.length + index) * 0.1 }}
            onClick={() => {
              if (link.onClick) {
                const result = link.onClick();
                if (result instanceof Promise) {
                  result.then(closeMobileMenu);
                } else {
                  closeMobileMenu();
                }
              } else {
                closeMobileMenu();
              }
            }}
          >
            {link.path ? (
              <Link to={link.path}>{link.label}</Link>
            ) : (
             <Link>{link.label}</Link> 
            )}
          </motion.li>
        ))}
      </ul>
    </nav>
  );
};

export default Navlinks;
