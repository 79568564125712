import React from "react";
import { motion } from "framer-motion";
import image6 from "./Images/image 6.png";
import image7 from "./Images/image 7.png";
import image8 from "./Images/image 8.png";
import image9 from "./Images/image 9.png";
import "./clients.css";

const clientImages = [image6, image7, image8, image9];

const Clients = () => {
  return (
    <section className="homeClient">
      <div className="container">
        <header className="clients-header">
          <h3>Our Clients</h3>
          <h1>OUR AWESOME PARTNERS</h1>
        </header>
        <div className="clients-logo-grid">
          {clientImages.map((image, index) => (
            <motion.div
              key={index}
              className="client-logo-img"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <img src={image} alt={`Client ${index + 1}`} />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Clients;
