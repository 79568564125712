import axiosInstance from "../axiosInstance";

const validateUserToken = async () => {
  try {
    const response = await axiosInstance.get("/auth/validate-token");
    return response.data.valid;
  } catch (error) {
    console.error("Error validating token:", error);
    return false;
  }
};

const createShipment = async (shipmentData) => {
  try {
    const response = await axiosInstance.post("/shipment/new", shipmentData);
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.error || error.message;
    console.error("Error creating shipment:", errorMessage);
    throw new Error(errorMessage);
  }
};

const checkShipperExists = async (shipperId) => {
  try {
    if (!shipperId) {
      throw new Error("Shipper ID is required");
    }

    const response = await axiosInstance.get(`/company/${shipperId}`);
    return response.data.company ? true : false;
  } catch (error) {
    console.error("Error checking shipper existence:", error.message);
    throw error;
  }
};

const getAvailableShipments = async () => {
  try {
    const response = await axiosInstance.get("/shipment/available");
    if (response.data.success && Array.isArray(response.data.shipments)) {
      return response.data.shipments;
    } else {
      throw new Error("Failed to fetch shipments");
    }
  } catch (error) {
    console.error("Error fetching available shipments:", error);
    throw error;
  }
};

const pastDelivery = async () => {
  try {
    const response = await axiosInstance.get("/delivery/complete");
    if (response.data.success && Array.isArray(response.data.shipments)) {
      return response.data.shipments;
    } else {
      throw new Error("Failed to fetch past deliveries");
    }
  } catch (error) {
    console.error("Error fetching past deliveries:", error);
    throw error;
  }
};

const updateShipmentStatus = async (shipmentId, status) => {
  try {
    const response = await axiosInstance.patch(`/shipments/${shipmentId}`, {
      status,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating shipment status:", error);
    throw new Error("Failed to update shipment status");
  }
};

const updateDelivery = async (shipment, shipmentId, updatedShipmentData) => {
  try {
    const response = await axiosInstance.patch(
      `/shipment/${shipmentId}`,
      updatedShipmentData
    );
    return response.data;
  } catch (error) {
    console.error("Error updating shipment:", error);
    throw new Error("Failed to update shipment");
  }
};

const pastShipments = async () => {
  try {
    const response = await axiosInstance.get("/shipment/complete");
    if (response.data.success && Array.isArray(response.data.shipments)) {
      return response.data.shipments;
    } else {
      throw new Error("Failed to fetch past shipments");
    }
  } catch (error) {
    console.error("Error fetching past shipments:", error);
    throw error;
  }
};

const fetchShipment = async (shipmentId, deliveryCompanyId) => {
  try {
    const response = await axiosInstance.post(
      `/shipment/${shipmentId}/pickup`,
      { deliveryCompanyId }
    );
    return response.data.shipment;
  } catch (error) {
    console.error("Error picking up shipment:", error);
    throw error;
  }
};

const inProgressShipments = async () => {
  try {
    const response = await axiosInstance.get("/shipments/in-progress");
    if (response.data.success && Array.isArray(response.data.shipments)) {
      return response.data.shipments;
    } else {
      throw new Error("Failed to fetch in-progress shipments");
    }
  } catch (error) {
    console.error("Error fetching in-progress shipments:", error);
    throw error;
  }
};

const deleteShipment = async (shipmentId) => {
  try {
    const response = await axiosInstance.delete(`/shipment/${shipmentId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting shipment:", error);
    throw error;
  }
};

const registerCompany = async (formData) => {
  try {
    const response = await axiosInstance.post("/auth/register", formData);
    if (response.data.token) {
      return {
        message: "Registration successful!",
        data: response.data,
      };
    } else {
      throw new Error("Registration failed without a clear error message.");
    }
  } catch (error) {
    console.error("Error during registration:", error);
    throw new Error(
      error.response?.data?.error || "An error occurred during registration."
    );
  }
};

const updateCompany = async (formData) => {
  try {
    const response = await axiosInstance.post(
      "/company/update-company",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating company info:", error);
    throw new Error(
      error.response?.data?.message ||
        "An error occurred during the company update."
    );
  }
};

const login = async (formData) => {
  try {
    const response = await axiosInstance.post("/auth/login", formData);
    if (!response.data.token || !response.data.user) {
      throw new Error("Incomplete login response.");
    }
    return {
      token: response.data.token,
      company: response.data.user,
    };
  } catch (error) {
    console.error("Login attempt failed:", error);
    throw new Error(
      error.response?.data?.error || "An error occurred during login."
    );
  }
};

export {
  login,
  registerCompany,
  createShipment,
  validateUserToken,
  getAvailableShipments,
  updateShipmentStatus,
  pastDelivery,
  deleteShipment,
  pastShipments,
  inProgressShipments,
  fetchShipment,
  checkShipperExists,
  updateCompany,
  updateDelivery,
};
