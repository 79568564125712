import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { SvgIcon } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import "./HomeAbout.css";

const truckAnimation = {
  initial: { opacity: 0, x: -100 },
  animate: {
    opacity: 1,
    x: 100,
    transition: { duration: 2, repeat: Infinity, repeatType: "reverse" },
  },
};

const HomeAbout = () => {
  return (
    <div className="HomeAbout">
      <div className="Container">
        <div className="About-Content">
          <div className="About-left">
            <motion.div
              initial="initial"
              animate="animate"
              variants={truckAnimation}
              className="TruckSvgContainer"
            >
              <SvgIcon
                component={LocalShippingIcon}
                style={{ fontSize: 80 }} 
              />
            </motion.div>
          </div>
          <div className="About-right">
            <h3>About Us -</h3>
            <h1>Who We Are</h1>
            <p>
              The Wright Logistics is a family-owned company committed to
              ensuring meticulous handling of all shipments...
            </p>
            <Link to="/about" className="ActiveButton">
              Learn More
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAbout;
