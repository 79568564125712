import React from "react";
import "./Tracking.css";
import { FormTitle } from "../components/formTitle";
import Header from "./Header";

function Tracking() {
  return (
    <div>
      <Header title="Package Tracking " />
      <div className="Container">
        <FormTitle className="sign-in-form__title" text="Load-Board" />
        <div className="loadboard">
          <h1>Coming Soon</h1>
        </div>
      </div>
    </div>
  );
}

export default Tracking;
