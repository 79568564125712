import {
  createContext,
  useReducer,
  useCallback,
  useContext,
  useMemo,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  getStoredToken,
  setStoredToken,
  removeStoredToken,
  setStoredCompany,
  removeStoredCompany,
  getStoredCompany,
  setStoredRefreshToken,
  removeStoredRefreshToken,
} from "../LocalStorageManager/LocalStorageManager";
import {
  login,
  registerCompany,
  validateUserToken,
  createShipment,
  getAvailableShipments,
  updateDelivery,
  pastDelivery,
  deleteShipment,
  pastShipments,
  updateShipmentStatus,
  inProgressShipments,
  fetchShipment,
  checkShipperExists,
  updateCompany,
} from "../authAPI/AuthAPI";

const ActionTypes = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT: "LOGOUT",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_ERROR: "REGISTER_ERROR",
  SET_LOADING: "SET_LOADING",
  SET_COMPANY: "SET_COMPANY",
};

const initialState = {
  company: null,
  loading: true,
  error: null,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN_SUCCESS:
    case ActionTypes.REGISTER_SUCCESS:
    case ActionTypes.SET_COMPANY:
      return {
        ...state,
        company: action.payload.company,
        loading: false,
        error: null,
      };
    case ActionTypes.LOGOUT:
      return { ...state, company: null, loading: false, error: null };
    case ActionTypes.REGISTER_ERROR:
      return { ...state, error: action.payload.error, loading: false };
    case ActionTypes.SET_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate();

  const clearAuthData = useCallback(() => {
    removeStoredToken();
    removeStoredCompany();
    removeStoredRefreshToken();
  }, []);

  useEffect(() => {
    const validateTokenAndSetup = async () => {
      dispatch({ type: ActionTypes.SET_LOADING, payload: true });
      const token = getStoredToken();
      if (token) {
        const isValid = await validateUserToken(token);
        if (isValid) {
          const company = getStoredCompany();
          dispatch({
            type: ActionTypes.LOGIN_SUCCESS,
            payload: { company },
          });
        } else {
          clearAuthData();
          dispatch({ type: ActionTypes.LOGOUT });
        }
      }
      dispatch({ type: ActionTypes.SET_LOADING, payload: false });
    };

    validateTokenAndSetup();
  }, [clearAuthData]);

  const handleLogout = useCallback(async () => {
    try {
      clearAuthData();
      dispatch({ type: ActionTypes.LOGOUT });
      navigate("/login", { replace: true });
    } catch (error) {
      console.error("Error during logout:", error);
    }
  }, [navigate, clearAuthData]);

  const handleLogin = useCallback(
    async (formData) => {
      try {
        const { token, company } = await login(formData);
        setStoredToken(token);
        setStoredCompany(company);
        dispatch({ type: ActionTypes.LOGIN_SUCCESS, payload: { company } });
        navigate("/loadboard");
      } catch (error) {
        console.error("Error during login:", error);
        dispatch({ type: ActionTypes.REGISTER_ERROR, payload: { error } });
      }
    },
    [navigate]
  );

  const handleCompanyRegister = useCallback(
    async (formData) => {
      try {
        await registerCompany(formData);
        navigate("/login");
      } catch (error) {
        console.error("Error during registration:", error);
        dispatch({ type: ActionTypes.REGISTER_ERROR, payload: { error } });
      }
    },
    [navigate]
  );

  const handleCreateShipment = useCallback(async (shipmentData) => {
    try {
      const shipperExists = await checkShipperExists(shipmentData.shipperId);
      if (!shipperExists) {
        throw new Error("Shipper not found");
      }

      const response = await createShipment(shipmentData);
      return response;
    } catch (error) {
      console.error("Error creating shipment:", error.message);
      throw error;
    }
  }, []);

  const fetchAvailableShipments = useCallback(async () => {
    try {
      const response = await getAvailableShipments();
      return response;
    } catch (error) {
      console.error("Error fetching shipments:", error);
      throw error;
    }
  }, []);

  const fetchPastShipments = useCallback(async () => {
    try {
      return await pastShipments();
    } catch (error) {
      console.error("Error fetching past shipments:", error);
      throw error;
    }
  }, []);

  const fetchInProgressShipments = useCallback(async () => {
    try {
      return await inProgressShipments();
    } catch (error) {
      console.error("Error fetching in-progress shipments:", error);
      throw error;
    }
  }, []);

  const pickUpShipment = useCallback(async (shipmentId, deliveryCompanyId) => {
    try {
      return await fetchShipment(shipmentId, deliveryCompanyId);
    } catch (error) {
      console.error("Error picking up shipment:", error);
      throw error;
    }
  }, []);

  const fetchPastDeliveries = useCallback(async () => {
    try {
      return await pastDelivery();
    } catch (error) {
      console.error("Error fetching past deliveries:", error);
      throw error;
    }
  }, []);

  const updateShipment = useCallback(async (shipmentId, formData) => {
    try {
      return await updateShipmentStatus(shipmentId, formData);
    } catch (error) {
      console.error("Error updating shipment status:", error);
      throw error;
    }
  }, []);

  const deleteShipment = useCallback(async (shipmentId) => {
    try {
      const response = await deleteShipment(shipmentId);
      return response;
    } catch (error) {
      console.error("Error deleting shipment:", error);
      throw error;
    }
  }, []);

  const updateCompanyInfo = useCallback(async (formData) => {
    try {
      const updatedCompany = await updateCompany(formData);
      dispatch({
        type: ActionTypes.SET_COMPANY,
        payload: { company: updatedCompany },
      });
      return updatedCompany;
    } catch (error) {
      console.error("Error updating company info:", error);
      throw error;
    }
  }, []);

  const handleDeliveryUpdate = useCallback(
    async (shipment, shipmentId, updatedShipmentData) => {
      try {
        if (!updatedShipmentData || typeof updatedShipmentData !== "object") {
          throw new Error("Invalid updated shipment data");
        }

        const { status, deliveryCompanyId } = updatedShipmentData;
        if (!status || !deliveryCompanyId) {
          throw new Error(
            "Required fields 'status' and 'deliveryCompanyId' are missing"
          );
        }

        if (status === "In Progress" && !deliveryCompanyId) {
          throw new Error(
            "Delivery company ID is required for picking up shipment"
          );
        }

        if (status === "In Progress" && shipment.status !== "Available") {
          throw new Error("Shipment is not available for pickup");
        }

        if (status === "Completed" && shipment.status !== "In Progress") {
          throw new Error("Shipment is not in progress");
        }

        const response = await updateDelivery(
          shipment,
          shipmentId,
          updatedShipmentData
        );
        return response;
      } catch (error) {
        console.error("Error updating shipment:", error);
        throw error;
      }
    },
    []
  );

  const contextValue = useMemo(
    () => ({
      company: state.company,
      isAuthenticated: !!state.company,
      loading: state.loading,
      error: state.error,
      handleLogout,
      handleLogin,
      updateCompanyInfo,
      handleCompanyRegister,
      handleCreateShipment,
      fetchAvailableShipments,
      handleDeliveryUpdate,
      fetchPastDeliveries,
      deleteShipment,
      fetchPastShipments,
      updateShipment,
      fetchInProgressShipments,
      pickUpShipment,
    }),
    [
      state,
      handleLogout,
      handleLogin,
      handleCompanyRegister,
      handleCreateShipment,
      fetchAvailableShipments,
      handleDeliveryUpdate,
      fetchPastDeliveries,
      deleteShipment,
      fetchPastShipments,
      updateShipment,
      fetchInProgressShipments,
      pickUpShipment,
    ]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
