import React from "react";
import "./App.css";
import HeaderNavbar from "./components/HeaderNavbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import LoadBoard from "./pages/LoadBoard";
import Footer from "./components/Footer";
import Tracking from "./pages/Tracking";
import Registration from "./components/Auth/Registration";
import Login from "./components/Auth/Login";
import Dashboard from "./components/Dashboard";



const App = () => {
  return (
      <div className="app">
          <HeaderNavbar />
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/service" exact element={<Services />} />
            <Route path="/tracking" exact element={<Tracking />} />
            <Route path="/loadboard" exact element={<LoadBoard />} />
            <Route path="/dashboard" exact element={<Dashboard/> } />
            <Route path="/about" exact element={<About />} />
            <Route path="/login" exact element={<Login />} />
            <Route path="/register" exact element={<Registration />} />
            <Route path="/contact" exact element={<Contact />} />
          </Routes>
          <Footer />
      </div>
  );
};
export default App;
