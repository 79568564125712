import React from "react";
import LoadList from "../LoadList/LoadList";

const LoadWidget = ({ shipments, handleShipmentUpdate }) => {
  return (
    <LoadList
      shipments={shipments}
      handleShipmentUpdate={handleShipmentUpdate}
    />
  );
};

export default LoadWidget;
