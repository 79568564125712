import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./ShipmentForm.css";
import { useAuth } from "../AuthContext/AuthContext";
import { useSnackbar } from "notistack";
import useDebounce from "../useDebounce";
import AddressAutocomplete from "../AddressAutocomplete";
import { getDistance } from "geolib";

const ShipmentForm = ({ onShipmentSubmit }) => {
  const { handleCreateShipment, company } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [departureDate, setDepartureDate] = useState("");
  const [arrivalDate, setArrivalDate] = useState("");
  const [additionalDetails, setAdditionalDetails] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [totalMiles, setTotalMiles] = useState(0);
  const [totalCost, setTotalCost] = useState(0);

  const [originStreet, setOriginStreet] = useState("");
  const [originCity, setOriginCity] = useState("");
  const [originState, setOriginState] = useState("");
  const [originZipCode, setOriginZipCode] = useState("");

  const [destinationStreet, setDestinationStreet] = useState("");
  const [destinationCity, setDestinationCity] = useState("");
  const [destinationState, setDestinationState] = useState("");
  const [destinationZipCode, setDestinationZipCode] = useState("");

  const debouncedDepartureDate = useDebounce(departureDate, 500);
  const debouncedArrivalDate = useDebounce(arrivalDate, 500);

  useEffect(() => {
    const fetchDistanceAndCost = async () => {
      if (originZipCode && destinationZipCode) {
        try {
          const originDetails = await getAddressDetails(originZipCode);
          const destinationDetails = await getAddressDetails(
            destinationZipCode
          );

          if (originDetails && destinationDetails) {
            const calculatedTotalMiles = calculateTotalMiles(
              originDetails.geometry.location,
              destinationDetails.geometry.location
            );

            setTotalMiles(calculatedTotalMiles);
            setTotalCost(calculateTotalCost(calculatedTotalMiles));
          } else {
            enqueueSnackbar("Failed to fetch address details", {
              variant: "error",
            });
          }
        } catch (error) {
          console.error("Error fetching distance and cost data: ", error);
          enqueueSnackbar("Error calculating distance and cost", {
            variant: "error",
          });
        }
      }
    };

    fetchDistanceAndCost();
  }, [originZipCode, destinationZipCode, enqueueSnackbar]);

  const getAddressDetails = async (zipCode) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        return data.results[0];
      }
      return null;
    } catch (error) {
      console.error("Error fetching address details: ", error);
      throw error;
    }
  };

  const calculateTotalMiles = (originLocation, destinationLocation) => {
    const distanceInMeters = getDistance(
      { latitude: originLocation.lat, longitude: originLocation.lng },
      { latitude: destinationLocation.lat, longitude: destinationLocation.lng }
    );
    const distanceInMiles = distanceInMeters / 1609.34;
    return distanceInMiles.toFixed(2);
  };

  const calculateTotalCost = (totalMiles) => {
    return (totalMiles * 3).toFixed(2);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const shipmentData = {
      shipperId: company._id,
      origin: {
        street: originStreet,
        city: originCity,
        state: originState,
        zipCode: originZipCode,
      },
      destination: {
        street: destinationStreet,
        city: destinationCity,
        state: destinationState,
        zipCode: destinationZipCode,
      },
      departureDate,
      arrivalDate,
      totalMiles,
      totalCost,
      additionalDetails,
      costPerMile: 3,
    };

    try {
      await handleCreateShipment(shipmentData);
      enqueueSnackbar("Shipment created successfully!", { variant: "success" });
      onShipmentSubmit();
      resetForm();
    } catch (error) {
      console.error("Error creating shipment: ", error);
      enqueueSnackbar("Failed to create shipment", { variant: "error" });
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setDepartureDate("");
    setArrivalDate("");
    setAdditionalDetails("");
    setTotalMiles(0);
    setTotalCost(0);
    setOriginStreet("");
    setOriginCity("");
    setOriginState("");
    setOriginZipCode("");
    setDestinationStreet("");
    setDestinationCity("");
    setDestinationState("");
    setDestinationZipCode("");
  };

  return (
    <motion.div
      className="shipment-form-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h2>Create a New Shipment</h2>
      <form onSubmit={handleSubmit}>
        <div className="shipment-form">
          <div className="shipment-wrapper">
            <div className="shipment-section">
              <h3>Departure</h3>
              <div className="departure-form-group">
                <input
                  type="date"
                  id="departureDate"
                  name="departureDate"
                  value={departureDate}
                  onChange={(e) => setDepartureDate(e.target.value)}
                  required
                />
              </div>
              <div className="shipment-group">
                <div className="loadboard-form-group">
                  <AddressAutocomplete
                    id="originStreet"
                    name="originStreet"
                    value={originStreet}
                    onChange={(value) => setOriginStreet(value)}
                    required
                  />
                </div>
              </div>
              <div className="shipment-group">
                <div className="loadboard-form-group">
                  <label htmlFor="originCity">City:</label>
                  <input
                    type="text"
                    id="originCity"
                    name="originCity"
                    value={originCity}
                    onChange={(e) => setOriginCity(e.target.value)}
                    required
                  />
                </div>
                <div className="loadboard-form-group">
                  <label htmlFor="originState">State:</label>
                  <input
                    type="text"
                    id="originState"
                    name="originState"
                    value={originState}
                    onChange={(e) => setOriginState(e.target.value)}
                    required
                  />
                </div>
                <div className="loadboard-form-group">
                  <label htmlFor="originZipCode">Zip Code:</label>
                  <input
                    type="text"
                    id="originZipCode"
                    name="originZipCode"
                    value={originZipCode}
                    onChange={(e) => setOriginZipCode(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="shipment-section">
              <h3>Destination</h3>
              <div className="arrival-form-group">
                <input
                  type="date"
                  id="arrivalDate"
                  name="arrivalDate"
                  value={arrivalDate}
                  onChange={(e) => setArrivalDate(e.target.value)}
                  required
                />
              </div>
              <div className="shipment-group">
                <div className="loadboard-form-group">
                  <AddressAutocomplete
                    id="destinationStreet"
                    name="destinationStreet"
                    value={destinationStreet}
                    onChange={(value) => setDestinationStreet(value)}
                    required
                  />
                </div>
              </div>
              <div className="shipment-group">
                <div className="loadboard-form-group">
                  <label htmlFor="destinationCity">City:</label>
                  <input
                    type="text"
                    id="destinationCity"
                    name="destinationCity"
                    value={destinationCity}
                    onChange={(e) => setDestinationCity(e.target.value)}
                    required
                  />
                </div>
                <div className="loadboard-form-group">
                  <label htmlFor="destinationState">State:</label>
                  <input
                    type="text"
                    id="destinationState"
                    name="destinationState"
                    value={destinationState}
                    onChange={(e) => setDestinationState(e.target.value)}
                    required
                  />
                </div>
                <div className="loadboard-form-group">
                  <label htmlFor="destinationZipCode">Zip Code:</label>
                  <input
                    type="text"
                    id="destinationZipCode"
                    name="destinationZipCode"
                    value={destinationZipCode}
                    onChange={(e) => setDestinationZipCode(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="shipment-pricing">
              <h3>Shipment Pricing</h3>
              <p>Total Miles: {totalMiles}</p>
              <p>Total Cost: ${totalCost}</p>
              <p>Cost Per Mile: $3</p>
            </div>
          </div>
          <div className="shipment-button">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              disabled={isSubmitting}
              className="submit-btn"
            >
              {isSubmitting ? "Submitting..." : "Submit Shipment"}
            </motion.button>
          </div>
        </div>
      </form>
    </motion.div>
  );
};

export default ShipmentForm;
