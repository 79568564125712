import React, { useState, useEffect } from "react";
import { useAuth } from "./AuthContext/AuthContext";
import { motion } from "framer-motion";
import DashboardContent from "./DashboardContent";
import "./Dashboard.css";

const Dashboard = () => {
  const {
    company,
    getPastShipments,
    getPastDelivery,
    getInProgress,
    updateCompanyInfo,
  } = useAuth();
  const [pastShipments, setPastShipments] = useState([]);
  const [pastDeliveries, setPastDeliveries] = useState([]);
  const [inProgressShipments, setInProgressShipments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [companyType, setCompanyType] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editedCompany, setEditedCompany] = useState({});
  const [logoFile, setLogoFile] = useState(null);

  const fetchData = async () => {
    try {
      let pastShipmentsData = [];
      let pastDeliveriesData = [];
      let inProgressShipmentsData = [];

      if (company) {
        setCompanyType(company.companyType);
        if (company.companyType === "Shipping") {
          pastShipmentsData = await getPastShipments();
        } else if (company.companyType === "Delivery") {
          pastDeliveriesData = await getPastDelivery();
          inProgressShipmentsData = await getInProgress();
        }
      }

      setPastShipments(pastShipmentsData || []);
      setPastDeliveries(pastDeliveriesData || []);
      setInProgressShipments(inProgressShipmentsData || []);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [company, getPastShipments, getPastDelivery, getInProgress]);

  useEffect(() => {
    if (company) {
      setEditedCompany({
        companyName: company.companyName,
        companyAddress: company.companyAddress,
        companyEmail: company.companyEmail,
        phoneNumber: company.phoneNumber,
        logoUrl: company.logoUrl,
      });
    }
  }, [company]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedCompany({ ...editedCompany, [name]: value });
  };

  const handleFileChange = (e) => {
    setLogoFile(e.target.files[0]);
  };

  const handleSave = async () => {
    const formData = new FormData();
    for (const key in editedCompany) {
      formData.append(key, editedCompany[key]);
    }
    if (logoFile) {
      formData.append("logo", logoFile);
    }
    try {
      await updateCompanyInfo(formData);
      setIsEditing(false);
      fetchData();
    } catch (error) {
      console.error("Error updating company info: ", error);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    if (company) {
      setEditedCompany({
        companyName: company.companyName,
        companyAddress: company.companyAddress,
        companyEmail: company.companyEmail,
        phoneNumber: company.phoneNumber,
        logoUrl: company.logoUrl,
      });
    }
  };

  if (!company) {
    return (
      <div className="no-company-message">
        <h1>Please login or register as a company first.</h1>
      </div>
    );
  }

  return (
    <motion.div
      className="dashboard-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="dashboard-content"
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: "spring", stiffness: 100 }}
      >
        <div className="dashboard-content-top">
          {isEditing ? (
            <div className="company-info-edit">
              <div className="form-group">
                <label>Company Name:</label>
                <input
                  type="text"
                  name="companyName"
                  value={editedCompany.companyName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label>Address:</label>
                <input
                  type="text"
                  name="companyAddress"
                  value={editedCompany.companyAddress}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label>Email:</label>
                <input
                  type="email"
                  name="companyEmail"
                  value={editedCompany.companyEmail}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label>Phone Number:</label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={editedCompany.phoneNumber}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label>Logo:</label>
                <input type="file" onChange={handleFileChange} />
              </div>
              <div className="button-group">
                <button onClick={handleSave}>Save</button>
                <button onClick={handleCancel}>Cancel</button>
              </div>
            </div>
          ) : (
            <div className="company-info">
              <motion.div
                className="company-img"
                whileHover={{ scale: 1.1 }}
                transition={{ duration: 0.3 }}
              >
                <img src={company?.logoUrl} alt="Company Logo" />
              </motion.div>
              <div className="company-info-detail">
                <p>Company: {company?.companyName}</p>
                <p>Address: {company?.companyAddress}</p>
                <p>Email: {company?.companyEmail}</p>
                <p>{company?.companyType} Company</p>
              </div>
              <button onClick={() => setIsEditing(true)}>Edit</button>
            </div>
          )}
          <motion.h1
            className="welcome-message"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            Welcome, {company?.companyName}!
          </motion.h1>
        </div>
        <DashboardContent
          pastShipments={pastShipments}
          pastDeliveries={pastDeliveries}
          inProgressShipments={inProgressShipments}
          isLoading={isLoading}
          companyType={companyType}
        />
      </motion.div>
    </motion.div>
  );
};

export default Dashboard;
