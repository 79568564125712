import React, { useState, useEffect, useCallback } from "react";
import { FormTitle } from "../components/formTitle";
import Header from "./Header";
import "./Loadboard.css";
import ShipmentForm from "../components/ShipmentForm/ShipmentForm";
import LoadWidget from "../components/LoadWidget/LoadWidget";
import { useAuth } from "../components/AuthContext/AuthContext";
import { motion } from "framer-motion";

const LoadBoard = () => {
  const { company, loading, fetchAvailableShipments } = useAuth();
  const [shipments, setShipments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchShipments = useCallback(async () => {
    setIsLoading(true);
    try {
      const fetchedShipments = await fetchAvailableShipments();
      setShipments(Array.isArray(fetchedShipments) ? fetchedShipments : []);
    } catch (error) {
      console.error("Error fetching shipments:", error);
    } finally {
      setIsLoading(false);
    }
  }, [fetchAvailableShipments]);

  useEffect(() => {
    fetchShipments();
  }, [fetchShipments]);

  const handleShipmentUpdate = useCallback(async () => {
    setIsLoading(true);
    await fetchShipments();
    setIsLoading(false);
  }, [fetchShipments]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!company) {
    return (
      <motion.div
        className="login-register-message"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <h1>Please login or register as a company first.</h1>
      </motion.div>
    );
  }

  return (
    <div className="loadboard">
      <Header title="Load Board" />
      <div className="loadboard-container">
        <FormTitle text="Load Board" />
        <ShipmentForm onShipmentSubmit={fetchShipments} />
        <LoadWidget
          shipments={shipments}
          handleShipmentUpdate={handleShipmentUpdate}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default LoadBoard;
