import React, { useState, useEffect } from "react";
import "./contact.css";
import Header from "./Header";
import img1 from "../components/Images/contactperson.png";
import Mapembeded from "../components/Mapembeded";
import { FormTitle } from "../components/formTitle";
import InlineError from "../components/validationFolder/InlineError";
import {
  validateEmail,
  validateFullName,
  validateMessage,
  validatePhone,
  validateSubject,
} from "../components/validationFolder/Validation";
import { axiosInstance } from "../components/config";
import { motion } from "framer-motion";

const Contact = () => {
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [messageSent, setMessageSent] = useState(false);

  useEffect(() => {
    validateFullName({ fullName, setFullNameError });
    validatePhone({ phone, setPhoneError });
    validateEmail({ email, setEmailError });
    validateSubject({ subject, setSubjectError });
    validateMessage({ message, setMessageError });
  }, [fullName, email, phone, subject, message]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const sendEmail = { fullName, email, phone, subject, message };
    try {
      await axiosInstance.post(
        "https://thewrightlogisticllc.herokuapp.com",
        sendEmail
      );
      setMessageSent(true);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  return (
    <div className="contact">
      <Header title="Contact" />
      <div className="Contact-Container">
        <FormTitle className="sign-in-form__title" text="GET IN TOUCH" />

        <div className="contact-wrapper">
          <form id="contact-form" onSubmit={handleSubmit}>
            <div className="form-header">
              <p>
                I am the best at this so contact now and I will build you the
                most amazing website you have ever had.
              </p>
            </div>
            <div className="contact-forms-container">
              <div className="form-group1">
                <div className="contact-form-group">
                  <input
                    className="form-input1"
                    type="text"
                    value={fullName}
                    required
                    onChange={(e) => setFullName(e.target.value)}
                    placeholder="Enter name..."
                  />
                  {fullNameError && <InlineError error={fullNameError} />}
                </div>
                <div className="contact-form-group">
                  <input
                    className="form-input1"
                    type="text"
                    value={phone}
                    required
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Enter Phone Number..."
                  />
                  {phoneError && <InlineError error={phoneError} />}
                </div>
              </div>

              <div className="form-group2">
                <div className="contact-form-group">
                  <input
                    className="form-input1"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter Email..."
                  />
                  {emailError && <InlineError error={emailError} />}
                </div>
                <div className="contact-form-group">
                  <input
                    className="form-input1"
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    placeholder="Subject..."
                  />
                  {subjectError && <InlineError error={subjectError} />}
                </div>
              </div>
              <div className="form-group3">
                <div className="contact-form-group">
                  <textarea
                    className="form-textarea"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Message...."
                  />
                  {messageError && <InlineError error={messageError} />}
                </div>

                <div className={`msg ${messageSent ? "msgAppear" : ""}`}>
                  Message has been sent!!!!
                </div>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="submit-btn"
                  title="Submit"
                  type="submit"
                >
                  Submit
                </motion.button>
              </div>
            </div>
          </form>

          <div className="contact-img">
            <img src={img1} alt="Contact Person" />
          </div>
        </div>
      </div>
      <div>
        <Mapembeded />
      </div>
    </div>
  );
};

export default Contact;
