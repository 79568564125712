import React from "react";
import { motion } from "framer-motion";
import Header from "./Header";
import ReactPlayer from "react-player/lazy";
import "./About.css";
import FillerSection from "../components/FillerSection";
import Clients from "../components/Clients";
import { FormTitle } from "../components/formTitle";

const About = () => {
  const containerVariants = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0.8 },
  };

  const contentVariants = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    hidden: { opacity: 0, y: 50 },
  };

  return (
    <div>
      <Header title="About" />
      <motion.div
        className="About-Container"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <FormTitle text="About Us" />
        <div className="About-Us-Content">
          <motion.div
            className="video-container"
            initial="hidden"
            animate="visible"
            variants={contentVariants}
          >
            <ReactPlayer
              url="https://youtu.be/3_-YVqrnUGE"
              width="100%"
              height="100%"
              className="react-player"
              playing
              controls
            />
          </motion.div>
          <motion.div
            className="about-content"
            initial="hidden"
            animate="visible"
            variants={contentVariants}
          >
            <div className="about-content-wrapper">
              <div>
                <h3>About The Wright Logistics LLC</h3>
                <p>
                  <span>Company Name: </span>The Wright Logistics LLC{" "}
                </p>
                <p>
                  {" "}
                  <span>Year Founded: </span>2021
                </p>
                <p>
                  {" "}
                  <span>Structure: </span>Privately Owned
                </p>
              </div>
              <div>
                <h3>Know About Our Company</h3>
                <p>
                  At The Wright Logistics, we are more than just a family-owned
                  company; we are a dedicated team focused on treating each of
                  our clients as an extension of our family. We recognize that
                  everything we do contributes to the well-being of our extended
                  family, and it is with this understanding that we approach our
                  work. Our commitment lies in executing each task efficiently,
                  providing you the freedom to nurture your business while we
                  handle your shipping needs. We acknowledge the challenge of
                  finding the right partner for shipping important items, and
                  that's why The Wright Logistics is here to offer peace of
                  mind. Our tracking system ensures transparency in every step
                  of the shipping process, allowing you to entrust your
                  logistics needs to us with confidence. As we witness our
                  family, which includes our valued clients, grow, we find joy
                  in contributing to their success here at The Wright Logistics.
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>
      <FillerSection />
      <Clients />
    </div>
  );
};

export default About;
